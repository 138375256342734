<template lang="pug">
    div
        h1 UserAuthority
</template>

<script>
    export default {
        name: "Index",
        data: function () {
            return {
                env: process.env
            }
        }
    }
</script>

<style scoped>

</style>